import React, { useState, useEffect } from "react"
import { auth, db } from "../../firebaseConfig"
import { onAuthStateChanged } from "firebase/auth"
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore"
import Navbar from "../Sections/Navbar"
import ClockLoader from "../Others/ClockLoader"
import "./Watchlist.css"

export default function Watchlist() {
  const [watchlist, setWatchlist] = useState({ list: {} })
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [authChecked, setAuthChecked] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser)
      setAuthChecked(true)
      if (currentUser) {
        fetchWatchlist(currentUser)
      } else {
        setLoading(false)
      }
    })

    return () => unsubscribe()
  }, [])

  const fetchWatchlist = async (currentUser) => {
    try {
      console.log("Fetching watchlist for UID:", currentUser.uid)

      const q = query(
        collection(db, "watchlist"),
        where("uid", "==", currentUser.uid)
      )

      const querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        const data = querySnapshot.docs.map((doc) => doc.data())
        setWatchlist(data[0] || { list: {} })
      } else {
        console.log("No matching documents found.")
      }
    } catch (error) {
      console.error("Error fetching watchlist:", error)
    } finally {
      setLoading(false)
    }
  }

  const handleCheckboxChange = async (key) => {
    try {
      if (!user) {
        console.log("No user is logged in")
        return
      }

      const q = query(
        collection(db, "watchlist"),
        where("uid", "==", user.uid)
      )

      const querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref
        const updatedList = { ...watchlist.list }
        delete updatedList[key]

        await updateDoc(docRef, { list: updatedList })
        setWatchlist((prevWatchlist) => ({
          ...prevWatchlist,
          list: updatedList,
        }))
      }
    } catch (error) {
      console.error("Error updating watchlist:", error)
    }
  }

  if (!authChecked) {
    return (
      <div>
        <Navbar />
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <ClockLoader color={"gray"} size={105} />
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div>
        <Navbar />
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <ClockLoader color={"gray"} size={105} />
        </div>
      </div>
    )
  }

  if (!user) {
    return (
      <div>
        <Navbar />
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            color: "white",
          }}
        >
          <div style={{ color: "white", fontSize: "10rem" }}>404</div>
          <div style={{ color: "gray" }}>Page not found</div>
          <div>Login First!</div>
        </div>
      </div>
    )
  }

  if (Object.keys(watchlist.list).length === 0) {
    return (
      <div>
        <Navbar />
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            color: "white",
          }}
        >
          <div style={{ color: "white", fontSize: "10rem" }}>404</div>
          <div style={{ color: "gray" }}>Page not found</div>
          <div>Add Anime to Watchlist to view them here!</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Navbar />
      <div className="watchlist-container">
        {Object.keys(watchlist.list).map((key) => (
          <div key={key} className="watchlist-individual-container">
            <div className="watchlist-title">
              {key.length > 25 ? `${key.slice(0, 25)}...` : key}
            </div>
            <div className="watchlist-image">
              <img
                className="watchlist-image-innner"
                src={watchlist.list[key][1]}
                alt="anime"
              />
            </div>
            <div className="watchlist-buttons">
              <a
                className="watchlist-a"
                href={watchlist.list[key][0]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="watchlist-watch-btn">Watch</button>
              </a>
              <button
                className="watchlist-remove-btn"
                onClick={() => handleCheckboxChange(key)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}