/* eslint-disable react-hooks/exhaustive-deps */

import axios from "axios";
import { useEffect, useState } from "react";
import CarouselRenderer from "../Layouts/CarouselRenderer";

const AnimeSection = ({ sectiontitle, url, id }) => {
  const [fetchedData, setFetchedData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        // Adjust the data based on section title
        const animeData =
          sectiontitle.toLowerCase() === "recent"
            ? response.data.data.trendingAnimes // Key for recent animes
            : sectiontitle.toLowerCase() === "special"
            ? response.data.data.topAiringAnimes // Key for special animes (or adjust if needed)
            : []; // Fallback if no valid section title
        setFetchedData(Array.isArray(animeData) ? animeData : []);
        console.log(animeData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setFetchedData([]); // Set to empty array in case of error
      }
    };

    fetchData();
  }, [sectiontitle, url]); // Re-run on sectiontitle or url change

  return (
    <section className="section section-anime" id={id}>
      {Array.isArray(fetchedData) && fetchedData.length > 0 && (
        <CarouselRenderer
          url={url}
          finalQuery={fetchedData}
          isAnimeCard={true}
          sectionTitle={sectiontitle}
        />
      )}
    </section>
  );
};

export default AnimeSection;
