const firebase = {
    apiKey: "AIzaSyCrIeZBwZCfdpGlk1T7pvt9GONQpp7BXFc",
    authDomain: "zangetsu-f1369.firebaseapp.com",
    projectId: "zangetsu-f1369",
    storageBucket: "zangetsu-f1369.appspot.com",
    messagingSenderId: "429884960669",
    appId: "1:429884960669:web:339559415ed69e77290974"
  };


export default firebase;