/* eslint-disable react-hooks/exhaustive-deps */

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Carousel from "react-elastic-carousel";
import { v4 as uuidv4 } from "uuid";
import HeroCard from "../Cards/HeroCard";
// import Navbar from "./Navbar";

const Hero = ({ setHeroSectionLoaded }) => {
  const carouselRef = useRef(null);
  let resetTimeout;
  const [fetchedAnime, setFetchedAnime] = useState([]);
  const baseURL = 'https://myaniwatchapi.vercel.app/api/v2/hianime/home';

  useEffect(() => {
    axios.get(`${baseURL}`).then(response => {
      setFetchedAnime(response.data.data.spotlightAnimes);
      setHeroSectionLoaded(response.data.success);
      // console.log(response.data.data.spotlightAnimes);
    }).catch(error => {
      console.error("Error fetching data:", error);
    });
}, [baseURL]);


  return (
    <>
      {fetchedAnime.length > 0 && (
        <header className="header">
          <section style={{ marginTop: '8rem'}} className="section-header">
            
            <Carousel
              enableAutoPlay={true}
              ref={carouselRef}
              showArrows={false}
              autoPlaySpeed={2000}
              onNextEnd={() => {
                clearTimeout(resetTimeout);
                resetTimeout = setTimeout(() => {
                  carouselRef?.current?.goTo(0);
                }, 4000);
              }}
              pagination={false}
            >
              {fetchedAnime.slice(1, -1).map((item) =>
                item.episodes &&
                item.id &&
                item.otherInfo &&
                // item.duration &&
                item.name &&
                item.description &&
                item.poster ? (
                  <HeroCard
                    key={uuidv4()}
                    id={item.id}
                    epcount={item.episodes[1]}
                    year={item.otherInfo[2]}
                    duration={item.otherInfo[1]}
                    title={item.name || "Error"}
                    description={item.description}
                    cover={item.poster}
                  ></HeroCard>
                ) : null
              )}
            </Carousel>
            
          </section>
        </header>
      )}
    </>
  );
};
export default Hero;
